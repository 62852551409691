import {
  CREATE_COMMUNICATION,
  RESET_DRAFT_COMMUNICATIONS,
  SET_COMMUNICATIONS,
  UPDATE_DRAFT_COMMUNICATIONS,
  UPDATE_PUBLISHED_COMMUNICATIONS,
  UPDATE_COMMUNICATION_PENDING_STATUS,
  REMOVE_DRAFT_COMMUNICATION,
  REMOVE_PUBLISHED_COMMUNICATION,
  SET_SPECIFIC_COMMUNICATION,
  SET_EDIT_STATUS,
  SET_SPECIFIC_COMMUNICATION_JOB,
  SET_SPECIFIC_COMMUNICATION_CONTACT,
} from "../../action-types/communication"
import { EDIT_CONTACT_SUCCESS } from "../../action-types/contacts"
import { UPDATE_JOB_DATA_SUCCESS } from "../../action-types/jobs"
import { SAVE_EDITED_COMPANY } from "../../action-types/companies"
import { generateId, getArrayUniqueValue } from "../../../common/helpers"
import { RESET_APPLICATION_DATA } from "../../action-types/user"

const initState = {
  draftItems: [],
  items: [],
  pending: false,
  isEdit: false,
  specificCommunication: {},
  specificCommunicationContact: {},
  specificCommunicationJob: {},
}
export const communicationReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_EDIT_STATUS: {
      return {
        ...state,
        isEdit: action.payload,
      }
    }
    case RESET_APPLICATION_DATA: {
      return {
        draftItems: [],
        items: [],
        pending: false,
        specificCommunication: {},
        isEdit: false,
      }
    }
    case SET_SPECIFIC_COMMUNICATION: {
      return {
        ...state,
        specificCommunication: action.payload,
      }
    }
    case SET_SPECIFIC_COMMUNICATION_CONTACT: {
      return {
        ...state,
        specificCommunicationContact: action.payload,
      }
    }
    case SET_SPECIFIC_COMMUNICATION_JOB: {
      return {
        ...state,
        specificCommunicationJob: action.payload,
      }
    }
    case EDIT_CONTACT_SUCCESS: {
      return {
        ...state,
        specificCommunicationContact:
          state.specificCommunicationContact.id === action.payload.id
            ? action.payload
            : state.specificCommunicationContact,
      }
    }
    case UPDATE_JOB_DATA_SUCCESS: {
      return {
        ...state,
        specificCommunicationJob:
          state.specificCommunicationJob.id === action.payload.id
            ? action.payload
            : state.specificCommunicationJob,
      }
    }
    case SAVE_EDITED_COMPANY: {
      return {
        ...state,
        specificCommunicationJob: {
          ...state.specificCommunicationJob,
          company:
            state.specificCommunicationJob?.company?.id === action.payload.id
              ? action.payload
              : state.specificCommunicationJob?.company,
        },
        specificCommunicationContact: {
          ...state.specificCommunicationContact,
          company:
            state.specificCommunicationContact?.company?.id ===
            action.payload.id
              ? action.payload
              : state.specificCommunicationContact?.company,
        },
      }
    }
    case CREATE_COMMUNICATION: {
      return {
        ...state,
        draftItems: [
          {
            ...action.payload,
            id: generateId(),
            ...state.draftItems,
          },
        ],
        items: [action.payload, ...state.items],
      }
    }
    case REMOVE_DRAFT_COMMUNICATION: {
      return {
        ...state,
        draftItems: state.draftItems.filter((item) => {
          return item.id !== action.payload
        }),
      }
    }
    case REMOVE_PUBLISHED_COMMUNICATION: {
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
        draftItems: state.draftItems.filter(
          (item) => item.id !== action.payload
        ),
      }
    }
    case UPDATE_COMMUNICATION_PENDING_STATUS: {
      return {
        ...state,
        pending: action.payload,
      }
    }
    case RESET_DRAFT_COMMUNICATIONS: {
      return {
        ...state,
        draftItems: [],
        // items: [],
      }
    }
    case UPDATE_DRAFT_COMMUNICATIONS: {
      const updated = state.draftItems.map((item) => {
        return item.id === action.payload.id
          ? { ...item, body: action.payload.body }
          : item
      })

      return {
        ...state,
        draftItems: updated,
      }
    }
    case SET_COMMUNICATIONS: {
      if (action.payload.id) {
        return {
          ...state,
          items: [action.payload, ...state.items],
        }
      } else
        return {
          ...state,
          items: getArrayUniqueValue(state.items, action.payload),
        }
    }
    case UPDATE_PUBLISHED_COMMUNICATIONS: {
      const updated = state.items.map((item) => {
        return item.id === action.payload.id ? action.payload : item
      })
      return {
        ...state,
        items: updated,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
